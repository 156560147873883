<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый лист"
        create
        url-update="/keyboard-list/update/:id"
        url-back="/keyboard-list"
    >
        <keyboard-list-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import KeyboardListForm from '@/components/forms/KeyboardListForm.vue'
export default {
    name: 'KeyboardListCreate',
    components: { FormView, KeyboardListForm }
}
</script>