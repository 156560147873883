<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="form.title"
            :error-messages="getErrors('form.title')"
            label="Название (для внутреннего использования)"
            name="title"
            type="text" 
            :color="$const.color.primary" 
            class="mb-5"
        ></v-text-field>

        <v-text-field 
            v-model="keysData.group"
            label="Название (публичное)"
            type="text" 
            :color="$const.color.primary" 
            class="mb-5"
            hint="Это назавание будет отображаться на кнопке переключения листов на вирт. клавиатуре. По этому рекомендуется выбрать короткое название."
            persistent-hint
        ></v-text-field>

        <div class="d-flex justify-space-around">
            <div class="d-flex flex-column">
                <v-label>Визуализация клавиатуры</v-label>
                <v-card class="keyboard-list-container my-4" flat>
                    <div
                        v-for="(row, ri) in keysData.keys"
                        :key="ri"
                        class="d-flex justify-space-between pos-rel w-100"
                    >
                        <v-card
                            v-for="(keycap, ki) in row"
                            :key="ki"
                            :style="keyStyle(keycap)"
                            class="keycap pos-rel"
                            :class="{ selected: keycap === selectedKeycap }"
                            @click="toggleSelectedKeycap(keycap)"
                        >
                            <v-icon v-if="keycap.key.includes('mdi-')"> {{ keycap.key }}</v-icon>
                            <span v-else>{{ keycap.key }}</span>
                            <v-icon
                                v-if="keycap === selectedKeycap"
                                title="Удалить клавишу"
                                size="20px"
                                class="pos-abs"
                                color="rgb(189, 0, 0)"
                                style="right: -5px; top: -5px;"
                                @click="keycapRemove(keycap)"
                            >mdi-close-circle</v-icon>
                        </v-card>
                        <v-icon
                            class="pos-abs"
                            title="Добавить клавишу"
                            :style="{right: `-${keyParams.width / 1.5}px`, top: `${keyParams.height / 2}px`, transform: 'translateY(-50%)'}"
                            @click="keycapAdd(row)"
                        >mdi-plus-circle</v-icon>
                    </div>
                </v-card>
            </div>
            <div v-if="selectedKeycap" class="d-flex flex-column">
                <v-label>Настройки клавиши</v-label>
                <v-card class="keycap-setting-container my-4" flat>

                    <v-slider
                        v-model="selectedKeycap.step"
                        label="Размер"
                        hide-ditails
                        max="12"
                        min="1"
                        dense
                        thumb-label="always"
                        thumb-size="16"
                        @change="keyboardValidate"
                    ></v-slider>

                    <v-text-field
                        v-model="selectedKeycap.key"
                        label="Значение"
                        hide-ditails
                        dense
                        color="$const.color.primary" 
                    />

                    <v-select
                        v-model="selectedKeycap.type"
                        :items="[{text: 'Обычный', value: 'common'}, {text: 'Функциональный', value: 'service'}]"
                        dense
                        label="Тип"
                        hide-ditails
                        @change="onKeycapTypeChanged(selectedKeycap)"
                    />

                    <v-text-field
                        v-if="selectedKeycap.type === 'service'"
                        v-model="selectedKeycap.name"
                        label="Название"
                        hide-ditails
                        dense
                        color="$const.color.primary" 
                    />
                </v-card>
            </div>
        </div>
            
        <v-alert
            v-if="keyboardErrors.length"
            dense
            type="warning"
        >
            <p v-for="(error, ei) in keyboardErrors" :key="ei" :class="{'mb-0': keyboardErrors.length === 1}">
                {{ error }}
            </p>
        </v-alert>

        <div style="height: 50px" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>

import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'


export default {
    components: { ActionButtons },
    props: {
        model: { type: Object }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            storeModule: 'keyboard_list',
            keysData: null,
            keyParams: { width: 38, height: 48, gap: 6 }, // Параметры клавиш
            selectedKeycap: null,
            keyboardErrors: [],
            form: {
                title: '',
                value: ''
            }
        };
    },
    validations() {
        return {
            form: {
                title: { required },
                value: { required }
            }
        }
    },
    created () {
        const keysData = this.form.value ? JSON.parse(this.form.value) : this.getDefaultKeysDataObject();
        keysData.keys = this.getNormalizedKeycapsData(keysData.keys);
        this.keysData = keysData;
    },
    methods: {
        toggleSelectedKeycap (keycap) {
            
            keycap && this.fillKeycapEmptyValues(keycap);

            this.selectedKeycap = this.selectedKeycap === keycap ? null : keycap;
        },
        onKeycapTypeChanged (keycap) {
            
            if (!keycap) { return }
            
            if (keycap.type === 'common') {
                keycap.name = null;
                keycap.act = null;
            }
        },
        fillKeycapEmptyValues (keycap) {
            
            if (!keycap) { return }

            if (!keycap.type)
                keycap.type = 'common';
        },
        // Метод возвращает нормализованные данные клавиш
        getNormalizedKeycapsData (keys) {
            if (!keys?.length) { return []; }

            return keys
                .map(keyData => {
                    const keys =
                        typeof keyData === "object" && keyData.row
                        ? keyData.row.split("")
                        : keyData.map(key => {
                            return typeof key === "object" && key.row ? key.row.split("") : key;
                            });
                    return _.flatten(keys);
                })
                .map(row =>
                    row.map(key => {
                        return typeof key === "object" ? key : { step: 1, key };
                    })
                );
        },
        getDefaultKeysDataObject () {
            return {
                group: '...',
                keys: new Array(3).fill(null).map(() => new Array(12).fill(null).map(() => this.getDefaultKeycapObject()))
            }
        },
        getDefaultKeycapObject () {
            return {
                type: "common", // common | service
                step: 1,
                key: "й",
                name: null,
                act: null // action
            }
        },
        keyStyle (keycapData) {
            return {
                width: `${this.keyParams.width * keycapData.step + this.keyParams.gap * (keycapData.step - 1)}px`,
                height: `${this.keyParams.height}px`,
            };
        },
        keycapRemove (keycap) {
            this.keysData.keys = this.keysData.keys.map(row => {
                return row.filter(_keycap => _keycap !== keycap);
            });
            this.selectedKeycap = null;
            this.keyboardValidate();
        },
        keycapAdd (row) {
            row.push(this.getDefaultKeycapObject());
            this.keyboardValidate();
        },
        keyboardValidate () {
            this.keyboardErrors = [];

            this.keysData.keys.forEach((row, ri) => {

                const columnsN = row.reduce((prev, item) => item.step + prev, 0);
                if (columnsN !== 12)
                    this.keyboardErrors.push(`Ошибка в ряду ${ri + 1}: суммарная ширина ряда должна равняться 12ти столбцам. Текущее значение - ${columnsN}`);
            });
        },
        // Calls before validation
        beforeSave () {
            this.form.value = this.keyboardErrors.length ? null : 'true';
        },
        // Calls after validation
        prepareForm (form) {
            form.value = JSON.stringify(this.keysData)
            console.log(form)
            return form
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.keycap-setting-container {
    background-color: #e3e6e9;
    width: 220px;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
    border-radius: 10px;
}
.keyboard-list-container {
    background-color: #c8cbce;
    width: 546px;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
    border-radius: 10px;

    .keycap {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.selected {
            background-color: #bbbdbe;
        }

        &:hover {
            background-color: #c8cbce;
        }
    }
}

</style>